import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Link } from 'gatsby';
import  NotFoundImage from '../assets/images/page_not_found.svg';
console.log(NotFoundImage);
export default function Error() {
    return (
        <Layout>
            <SEO title="Not Found" description="Page not found"></SEO>
            <div className="section-container" id="not-found">
                <img alt="404-title" className="illustration" src={NotFoundImage}/>
                <p>Oops. The page you are looking for doesn't exist.</p>
                <div className="btn-container">
                    <Link className="btn btn-full" to="/">GO BACK HOME</Link>
                </div>
            </div>
        </Layout>
    )
}
